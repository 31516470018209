import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Box, Container, Grid, Layout } from '../components';
import Blog from '../components/Blog';
import { appENV } from '../config';

const Blogs = () => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        blogs {
          edges {
            node {
              id
              title
              slug
              blog_options {
                blogFeatured
                blogProduction
              }
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  `);

  const allBlogs = data.wpgraphql.blogs.edges;

  const blogs = allBlogs.filter(item => {
    if (appENV === 'production') {
      return item.node.blog_options.blogProduction;
    }

    return true;
  });
  const featuredBlog = blogs?.find(item => item.node.blog_options.blogFeatured);
  const otherBlogs = blogs?.filter(item => !item.node.blog_options.blogFeatured);

  return (
    <Layout title="Blogs">
      <Container mt={{ _: 8, md: 9 }}>
        <Box p={{ _: 2, md: 0 }} mb={{ _: 8, md: 0 }}>
          {featuredBlog && <Blog type="large" blog={featuredBlog.node} />}
        </Box>
        <Box p={{ _: 2, md: 0 }} mt={{ _: 6, md: 10 }}>
          <Grid gap={{ xs: 2, md: 10 }} gridRowGap={9} gridTemplateColumns={{ _: '1fr', sm: '1fr 1fr' }}>
            {otherBlogs.map(item => (
              <Blog key={item.node.id} blog={item.node} />
            ))}
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};

export default Blogs;
